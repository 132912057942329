import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/global/layout';
import Container from '../components/global/container';
import Header from '../components/global/header';
import PlayButton from '../static/play--button.svg';

import ShibonCover from '../static/social-examples/shibon-cover.png';
import ShibonVideo from '../static/social-examples/EWY_Shibon_Social_Video.mp4';

import ExamCover from '../static/social-examples/results-cover.png';
import ExamVideo from '../static/social-examples/Greta-Exam-Results-Day.mp4';

import VapeCover from '../static/social-examples/vaping-cover.png';
import VapeVideo from '../static/social-examples/MM_Greta_New-on-the-blog.mp4';

import LeedsCover from '../static/social-examples/next-step-cover.png';
import LeedsVideo from '../static/social-examples/NHS-Recruit.mp4';

import SocialDdfCover from '../static/video-examples/Candia_The Chocolate Penguin.png';
import SocialDdf from '../static/video-examples/Candia_The Chocolate Penguin.mp4';

import SocialLebcCover from '../static/video-examples/EWY_Bilaal.png';
import SocialLebc from '../static/video-examples/EWY_Bilaal.mp4';

import NaomiCover from '../static/video-examples/MindMate-Naomi.png';
import NaomiVideo from '../static/video-examples/MindMate-Naomi.mp4';

import SocialCandiaCover from '../static/social-examples/social-candia-cover.png';
import SocialCandia from '../static/social-examples/social-candia.mp4';

import RebSocial from '../static/social-examples/EWY_Rebecca_social.mp4';
import RebCover from '../static/social-examples/EWY_Rebecca_social-cover.png';

import Dolls from '../static/video-examples/Aviva-Dolls-House.mp4';
import DollsCover from '../static/video-examples/Aviva-Dolls-House.png';

import Toy from '../static/video-examples/Aviva-Toy-Wedding.mp4';
import ToyCover from '../static/video-examples/Aviva-Toy-Wedding.png';

import Shar from '../static/video-examples/EWY_Shar.mp4';
import SharCover from '../static/video-examples/EWY_Shar.png';

import Gtech from '../static/video-examples/Gtech.mp4';
import GtechCover from '../static/video-examples/Gtech.png';

const VideoExamplePage = (props) => {
    const { data, errors } = props;

    const shibonVideo = useRef();
    const playButtonS = useRef();

    const [isPlayingSV, setIsPlayingSV] = useState(false);

    const handlePlayS = e => {
        if(!isPlayingSV) {
            setIsPlayingSV(true);
            shibonVideo.current.play();
        } else {
            setIsPlayingSV(false);
            shibonVideo.current.pause();
        }
    }

    const examVideo = useRef();
    const playButtonE = useRef();

    const [isPlayingEV, setIsPlayingEV] = useState(false);

    const handlePlayE = e => {
        if(!isPlayingEV) {
            setIsPlayingEV(true);
            examVideo.current.play();
        } else {
            setIsPlayingEV(false);
            examVideo.current.pause();
        }
    }

    const vapeVideo = useRef();
    const playButtonV = useRef();

    const [isPlayingV, setIsPlayingV] = useState(false);

    const handlePlayV = e => {
        if(!isPlayingV) {
            setIsPlayingV(true);
            vapeVideo.current.play();
        } else {
            setIsPlayingV(false);
            vapeVideo.current.pause();
        }
    }

    const naomiVideo = useRef();
    const playButtonN = useRef();

    const [isPlayingN, setIsPlayingN] = useState(false);

    const handlePlayN = e => {
        if(!isPlayingN) {
            setIsPlayingN(true);
            naomiVideo.current.play();
        } else {
            setIsPlayingN(false);
            naomiVideo.current.pause();
        }
    }

    const leedsVideo = useRef();
    const playButtonL = useRef();

    const [isPlayingL, setIsPlayingL] = useState(false);

    const handlePlayL = e => {
        if(!isPlayingL) {
            setIsPlayingL(true);
            leedsVideo.current.play();
        } else {
            setIsPlayingL(false);
            leedsVideo.current.pause();
        }
    }

    const ddfVideo = useRef();
    const playButtonDdf = useRef();

    const [isPlayingDdf, setIsPlayingDdf] = useState(false);

    const handlePlayDdf = e => {
        if(!isPlayingDdf) {
            setIsPlayingDdf(true);
            ddfVideo.current.play();
        } else {
            setIsPlayingDdf(false);
            ddfVideo.current.pause();
        }
    }

    const candiaVideo = useRef();
    const playButtonCandia = useRef();

    const [isPlayingCandia, setIsPlayingCandia] = useState(false);

    const handlePlayCandia = e => {
        if(!isPlayingCandia) {
            setIsPlayingCandia(true);
            candiaVideo.current.play();
        } else {
            setIsPlayingCandia(false);
            candiaVideo.current.pause();
        }
    }

    const lebcVideo = useRef();
    const playButtonLebc = useRef();

    const [isPlayingLebc, setIsPlayingLebc] = useState(false);

    const handlePlayLebc = e => {
        if(!isPlayingLebc) {
            setIsPlayingLebc(true);
            lebcVideo.current.play();
        } else {
            setIsPlayingLebc(false);
            lebcVideo.current.pause();
        }
    }

        const rebSocial = useRef();
            const playButtonReb = useRef();

            const [isPlayingReb, setIsPlayingReb] = useState(false);

            const handlePlayReb = e => {
                if(!isPlayingReb) {
                    setIsPlayingReb(true);
                    rebSocial.current.play();
                } else {
                    setIsPlayingReb(false);
                    rebSocial.current.pause();
                }
            }

    const dollsVideo = useRef();
        const playButtonDoll = useRef();

        const [isPlayingDoll, setIsPlayingDoll] = useState(false);

        const handlePlayDoll = e => {
            if(!isPlayingDoll) {
                setIsPlayingDoll(true);
                dollsVideo.current.play();
            } else {
                setIsPlayingDoll(false);
                dollsVideo.current.pause();
            }
        }

    const toyVideo = useRef();
    const playButtonToy = useRef();

    const [isPlayingToy, setIsPlayingToy] = useState(false);

    const handlePlayToy = e => {
        if(!isPlayingToy) {
            setIsPlayingToy(true);
            toyVideo.current.play();
        } else {
            setIsPlayingToy(false);
            toyVideo.current.pause();
        }
    }

    const sharVideo = useRef();
    const playButtonShar = useRef();

    const [isPlayingShar, setIsPlayingShar] = useState(false);

    const handlePlayShar = e => {
        if(!isPlayingShar) {
            setIsPlayingShar(true);
            sharVideo.current.play();
        } else {
            setIsPlayingShar(false);
            sharVideo.current.pause();
        }
    }

    const gtechVideo = useRef();
    const playButtonGtech = useRef();

    const [isPlayingGtech, setIsPlayingGtech] = useState(false);

    const handlePlayGtech = e => {
        if(!isPlayingGtech) {
            setIsPlayingGtech(true);
            gtechVideo.current.play();
        } else {
            setIsPlayingGtech(false);
            gtechVideo.current.pause();
        }
    }

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <>
        <Header headerStyle="clear" />
        <Layout>
        <Container>
            <div className='site-wide'>
                <div className='aviva--page-banner-image showreel social-examples' style={{ paddingBottom: "80px" }}>
                    <div className='video--wrapper'>
                        {isPlayingDdf ? null :
                            <div className='video--overlay' onClick={handlePlayDdf}>
                                <img src={PlayButton} ref={playButtonDdf} />
                            </div>
                        }
                        <video
                            ref={ddfVideo}
                            className="hero__media"
                            controls="false"
                            poster={SocialDdfCover}
                        >
                            <source src={SocialDdf} type="video/mp4" />
                        </video>
                    </div>

                    <div className='video--wrapper'>
                        {isPlayingCandia ? null :
                            <div className='video--overlay' onClick={handlePlayCandia}>
                                <img src={PlayButton} ref={playButtonCandia} />
                            </div>
                        }
                        <video
                            ref={candiaVideo}
                            className="hero__media"
                            controls="false"
                            poster={SocialCandiaCover}
                        >
                            <source src={SocialCandia} type="video/mp4" />
                        </video>
                    </div>

                    <div className='video--wrapper'>
                        {isPlayingLebc ? null :
                            <div className='video--overlay' onClick={handlePlayLebc}>
                                <img src={PlayButton} ref={playButtonLebc} />
                            </div>
                        }
                        <video
                            ref={lebcVideo}
                            className="hero__media"
                            controls="false"
                            poster={SocialLebcCover}
                        >
                            <source src={SocialLebc} type="video/mp4" />
                        </video>
                    </div>

                    <div className='video--wrapper'>
                        {isPlayingSV ? null :
                            <div className='video--overlay' onClick={handlePlayS}>
                                <img src={PlayButton} ref={playButtonS} />
                            </div>
                        }
                        <video
                            ref={shibonVideo}
                            className="hero__media"
                            controls="false"
                            poster={ShibonCover}
                        >
                            <source src={ShibonVideo} type="video/mp4" />
                        </video>
                    </div>

                    <div className='video--wrapper'>
                        {isPlayingReb ? null :
                            <div className='video--overlay' onClick={handlePlayReb}>
                                <img src={PlayButton} ref={playButtonReb} />
                            </div>
                        }
                        <video
                            ref={rebSocial}
                            className="hero__media"
                            controls="false"
                            poster={RebCover}
                        >
                            <source src={RebSocial} type="video/mp4" />
                        </video>
                    </div>

                    <div className='video--wrapper'>
                        {isPlayingL ? null :
                            <div className='video--overlay' onClick={handlePlayL}>
                                <img src={PlayButton} ref={playButtonL} />
                            </div>
                        }
                        <video
                            ref={leedsVideo}
                            className="hero__media"
                            controls="false"
                            poster={LeedsCover}
                        >
                            <source src={LeedsVideo} type="video/mp4" />
                        </video>
                    </div>

                    <div className='video--wrapper'>
                        {isPlayingV ? null :
                            <div className='video--overlay' onClick={handlePlayV}>
                                <img src={PlayButton} ref={playButtonV} />
                            </div>
                        }
                        <video
                            ref={vapeVideo}
                            className="hero__media"
                            controls="false"
                            poster={VapeCover}
                        >
                            <source src={VapeVideo} type="video/mp4" />
                        </video>
                    </div>

                    <div className='video--wrapper'>
                        {isPlayingEV ? null :
                            <div className='video--overlay' onClick={handlePlayE}>
                                <img src={PlayButton} ref={playButtonE} />
                            </div>
                        }
                        <video
                            ref={examVideo}
                            className="hero__media"
                            controls="false"
                            poster={ExamCover}
                        >
                            <source src={ExamVideo} type="video/mp4" />
                        </video>
                    </div>

                    <div className='video--wrapper'>
                        {isPlayingN ? null :
                            <div className='video--overlay' onClick={handlePlayN}>
                                <img src={PlayButton} ref={playButtonN} />
                            </div>
                        }
                        <video
                            ref={naomiVideo}
                            className="hero__media"
                            controls="false"
                            poster={NaomiCover}
                        >
                            <source src={NaomiVideo} type="video/mp4" />
                        </video>
                    </div>
                </div>

                <div className='gtech-section'>
                    <div className='video--wrapper'>
                        {isPlayingShar ? null :
                            <div className='video--overlay' onClick={handlePlayShar}>
                                <img src={PlayButton} ref={playButtonShar} />
                            </div>
                        }
                        <video
                            ref={sharVideo}
                            className="hero__media"
                            controls="false"
                            poster={SharCover}
                        >
                            <source src={Shar} type="video/mp4" />
                        </video>
                    </div>
                    <div className='video--wrapper gtech'>
                        {isPlayingGtech ? null :
                            <div className='video--overlay' onClick={handlePlayGtech}>
                                <img src={PlayButton} ref={playButtonGtech} />
                            </div>
                        }
                        <video
                            ref={gtechVideo}
                            className="hero__media"
                            controls="false"
                            poster={GtechCover}
                        >
                            <source src={Gtech} type="video/mp4" />
                        </video>
                    </div>
                </div>

                <div className='ewy-landing' style={{ marginTop: 0 }}>
                    <div className='aviva--page-banner-image showreel'>
                        <div className='video--wrapper'>
                            {isPlayingDoll ? null :
                                <div className='video--overlay' onClick={handlePlayDoll}>
                                    <img src={PlayButton} ref={playButtonDoll} />
                                </div>
                            }
                            <video
                                ref={dollsVideo}
                                className="hero__media"
                                controls="false"
                                poster={DollsCover}
                            >
                                <source src={Dolls} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    <div className='aviva--page-banner-image showreel'>
                        <div className='video--wrapper'>
                            {isPlayingToy ? null :
                                <div className='video--overlay' onClick={handlePlayToy}>
                                    <img src={PlayButton} ref={playButtonToy} />
                                </div>
                            }
                            <video
                                ref={toyVideo}
                                className="hero__media"
                                controls="false"
                                poster={ToyCover}
                            >
                                <source src={Toy} type="video/mp4" />
                            </video>
                        </div>

                    </div>
                </div>

            </div>
        </Container>
        </Layout>
    </>
  )
};

VideoExamplePage.propTypes = {
    data: PropTypes.object,
    errors: PropTypes.object
  };

export default VideoExamplePage;

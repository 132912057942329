import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/global/layout';
import Container from '../components/global/container';
import Header from '../components/global/header';
import PlayButton from '../static/play--button.svg';

import AvivaCover from '../static/videos/Aviva-B2C-Master-A-2022.png';
import AvivaVideo from '../static/videos/Aviva-B2C-Master-A-2022.mp4';

import BionicCover from '../static/videos/Bionic-Benefits-Generic.png';
import BionicVideo from '../static/videos/Bionic-Benefits-Generic.mp4';

import CognisessCover from '../static/videos/Cognisess-Predictive-People-Analytics.png';
import CognisessVideo from '../static/videos/Cognisess-Predictive-People-Analytics.mp4';

import PizzaCover from '../static/videos/Technically-Perfect-Pizza.png';
import PizzaVideo from '../static/videos/Technically-Perfect-Pizza.mp4';

import TalentCover from '../static/videos/The-Talent-Hub-Exaplainer.png';
import TalentVideo from '../static/videos/The-Talent-Hub-Exaplainer.mp4';

import VisaCover from '../static/videos/VISA-EDS.png';
import VisaVideo from '../static/videos/VISA-EDS.mp4';


const VideosPage = (props) => {
    const { data, errors } = props;

    const avivaVideo = useRef();
    const playButtonA = useRef();

    const [isPlayingA, setIsPlayingA] = useState(false);

    const handlePlayA = e => {
        if(!isPlayingA) {
            setIsPlayingA(true);
            avivaVideo.current.play();
        } else {
            setIsPlayingA(false);
            avivaVideo.current.pause();
        }
    }

    const bionicVideo = useRef();
    const playButtonB = useRef();

    const [isPlayingB, setIsPlayingB] = useState(false);

    const handlePlayB = e => {
        if(!isPlayingB) {
            setIsPlayingB(true);
            bionicVideo.current.play();
        } else {
            setIsPlayingB(false);
            bionicVideo.current.pause();
        }
    }

    const cognisessVideo = useRef();
    const playButtonC = useRef();

    const [isPlayingC, setIsPlayingC] = useState(false);

    const handlePlayC = e => {
        if(!isPlayingC) {
            setIsPlayingC(true);
            cognisessVideo.current.play();
        } else {
            setIsPlayingC(false);
            cognisessVideo.current.pause();
        }
    }

    const pizzaVideo = useRef();
    const playButtonP = useRef();

    const [isPlayingP, setIsPlayingP] = useState(false);

    const handlePlayP = e => {
        if(!isPlayingP) {
            setIsPlayingP(true);
            pizzaVideo.current.play();
        } else {
            setIsPlayingP(false);
            pizzaVideo.current.pause();
        }
    }

    const talentVideo = useRef();
    const playButtonT = useRef();

    const [isPlayingT, setIsPlayingT] = useState(false);

    const handlePlayT = e => {
        if(!isPlayingT) {
            setIsPlayingT(true);
            talentVideo.current.play();
        } else {
            setIsPlayingT(false);
            talentVideo.current.pause();
        }
    }

    const visaVideo = useRef();
    const playButtonV = useRef();

    const [isPlayingV, setIsPlayingV] = useState(false);

    const handlePlayV = e => {
        if(!isPlayingV) {
            setIsPlayingV(true);
            visaVideo.current.play();
        } else {
            setIsPlayingV(false);
            visaVideo.current.pause();
        }
    }

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  return (
    <>
        <Header headerStyle="clear" />
        <Layout>
        <Container>
            <div className='site-wide'>
                
                <div className='ewy-landing'>
                    <div className='aviva--page-banner-image showreel' id="aviva">
                        <div className='video--wrapper'>
                            {isPlayingA ? null :
                                <div className='video--overlay' onClick={handlePlayA}>
                                    <img src={PlayButton} ref={playButtonA} />
                                </div>
                            }
                            <video
                                ref={avivaVideo}
                                className="hero__media"
                                controls="false"
                                poster={AvivaCover}
                            >
                                <source src={AvivaVideo} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    <div className='aviva--page-banner-image showreel' id="aviva">
                        <div className='video--wrapper'>
                            {isPlayingB ? null :
                                <div className='video--overlay' onClick={handlePlayB}>
                                    <img src={PlayButton} ref={playButtonB} />
                                </div>
                            }
                            <video
                                ref={bionicVideo}
                                className="hero__media"
                                controls="false"
                                poster={BionicCover}
                            >
                                <source src={BionicVideo} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    <div className='aviva--page-banner-image showreel' id="aviva">
                        <div className='video--wrapper'>
                            {isPlayingC ? null :
                                <div className='video--overlay' onClick={handlePlayC}>
                                    <img src={PlayButton} ref={playButtonC} />
                                </div>
                            }
                            <video
                                ref={cognisessVideo}
                                className="hero__media"
                                controls="false"
                                poster={CognisessCover}
                            >
                                <source src={CognisessVideo} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    <div className='aviva--page-banner-image showreel' id="aviva">
                        <div className='video--wrapper'>
                            {isPlayingP ? null :
                                <div className='video--overlay' onClick={handlePlayP}>
                                    <img src={PlayButton} ref={playButtonP} />
                                </div>
                            }
                            <video
                                ref={pizzaVideo}
                                className="hero__media"
                                controls="false"
                                poster={PizzaCover}
                            >
                                <source src={PizzaVideo} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    <div className='aviva--page-banner-image showreel' id="aviva">
                        <div className='video--wrapper'>
                            {isPlayingT ? null :
                                <div className='video--overlay' onClick={handlePlayT}>
                                    <img src={PlayButton} ref={playButtonT} />
                                </div>
                            }
                            <video
                                ref={talentVideo}
                                className="hero__media"
                                controls="false"
                                poster={TalentCover}
                            >
                                <source src={TalentVideo} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    <div className='aviva--page-banner-image showreel' id="aviva">
                        <div className='video--wrapper'>
                            {isPlayingV ? null :
                                <div className='video--overlay' onClick={handlePlayV}>
                                    <img src={PlayButton} ref={playButtonV} />
                                </div>
                            }
                            <video
                                ref={visaVideo}
                                className="hero__media"
                                controls="false"
                                poster={VisaCover}
                            >
                                <source src={VisaVideo} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                </div>
            </div>
        </Container>
        </Layout>
    </>
  )
};

VideosPage.propTypes = {
    data: PropTypes.object,
    errors: PropTypes.object
  };

export default VideosPage;
